import React from "react";
import './ScoreBar.css';

const ScoreBar = ({ limitCoins, dailyCoins }) => {
    const progressBarFillWidth = dailyCoins * 100 / limitCoins
    const progressBarFillText = dailyCoins;

    return (
        <div className="ScoreBar">
            <div className="ScoreBarInfo">
                <div className="ScoreBarInfoIcon">⚡</div>
                <div className="ScoreBarInfoLimit"><span>Daily limit:</span> {progressBarFillText}</div>
                <div className="ScoreBarInfoProgress">
                    <div className="ProgressBar">
                        <div className="ProgressBarFill" style={{ width: `${progressBarFillWidth}%` }}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScoreBar;