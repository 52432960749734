import { useEffect, useState} from 'react';
import useApi from '../services/api';

export default function useGroups() {
  const [groupsData, updateGroupsData] = useState();
  const { fetchGroups } = useApi();

  useEffect(() => {
      const getGroupsFromDB = async () => {
          const data = await fetchGroups();
          updateGroupsData(data);
      }

      if (!groupsData) getGroupsFromDB();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return groupsData;
}