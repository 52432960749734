import React, { createContext, useContext } from 'react';

// Создаем контекст
const SupabaseContext = createContext(null);

// Создаем хук для использования контекста
export function useSupabase() {
  return useContext(SupabaseContext);
}

// Создаем компонент-провайдер
export const SupabaseProvider = ({ children, supabaseClient }) => {
  return (
    <SupabaseContext.Provider value={supabaseClient}>
      {children}
    </SupabaseContext.Provider>
  );
};
