import React, { createContext, useState, useEffect, useCallback } from 'react';
import useTelegram from '../hooks/useTelegram';
import useApi from '../services/api';
import Loader from "../components/Loader";
import QR from "../components/QR";

// Создание контекста
export const DataContext = createContext();

// Создание провайдера контекста
export const DataProvider = ({ children }) => {
  const [data, setData] = useState({user: null});

  // Переносим инициализацию юзера прям в контекст
  const {tg, user: tgUser } = useTelegram();
  const { fetchUserData } = useApi();

  const initData = useCallback(async () => {
    const user = await fetchUserData(tgUser);

    setData(data => ({...data, user: user[0]}));
  }, [fetchUserData, setData, tgUser]);

  const swipeChangeData = (direction) => {
    let cost = 0;
    if (direction === 'up') { cost = 500 }
    else { cost = 100 }

    const balance = data.user.balance - cost;

    setData(data => ({...data, user: {
      ...data.user,
      balance
    }}));
  };

  // Значение, передаваемое в контекст
  const value = { data, setData, initData, swipeChangeData };

  useEffect(() => {
    if (!data.user) initData();
  }, [data, initData])  


  const isMobile = ["android", "android_x", "ios"].indexOf(tg.platform) >= 0;

  if (!isMobile) {
    return <QR />
  }

  if (!data.user) {
    return <Loader/>
  }

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};