import React from "react";
import './UsersList.css';

const UsersList = ({ users, type }) => {
    return (
        <div className="UsersList">
            {users ? users.map(item => (
                <div key={item.telegram_id} className="UsersListItem">
                    <div className="UsersListItemInner">
                        <div className="UsersListItemImage" style={{backgroundImage: `url("https://vfonzbsnatcrbykoagvk.supabase.co/storage/v1/object/public/images/user/${item.telegram_id}.jpg")`}}></div>
                        <div className="UsersListItemContent">
                            <div className="UsersListItemContentTitle">{item.first_name}</div>
                            <div className="UsersListItemContentScore">
                                {/* <p className="UsersListItemContentReward"># matches</p> */}
                            </div>
                        </div>
                        {type === "referrer" && (
                            <div className="UsersListItemInviteReward">{item.is_premium ? "+10,000" : "+2,500"}</div>
                        )}
                    </div>
                </div>
            )): (
                <>Loader</>
            )}
        </div>
    )
}

export default UsersList;