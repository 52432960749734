import React from "react";
import { useNavigate } from 'react-router-dom';
import useBackButton from "../../hooks/useBackButton";
import useGroups from "../../hooks/useGroups";

import GroupsList from '../../components/GroupsList';

import './Groups.css';

const Groups = () => {
    const navigate = useNavigate();
    const groups = useGroups()

    useBackButton(() => navigate(-1));

    return (
        <div className="Page PageGroups">
            <div className="PageGroupsHeader">
                <div className="PageGroupsTitleWrapper">
                    <span className="PageGroupsTitle">Join Group!</span>
                </div>
                <div className="PageGroupsSubtitle">These groups are popular.<br/>Do you wanna join?</div>
            </div>
            <div className="PageGroupsList">
                <GroupsList groups={groups} />
            </div>
        </div>
    )
}

export default Groups;