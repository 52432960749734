import React from "react";
import classNames from "classnames";
import './Label.css';

export const Label = ({
  label = "",
  labelClassName,
  valueClassName,
  children,
  className
}) => {
  return (
    <div className={classNames(
      "label",
      className
    )}>
      <div className={classNames(
        "labelText",
        labelClassName
      )}
      >
        <span>{label}</span>
      </div>
      <div className={valueClassName}>{children}</div>
    </div>
  );
};
