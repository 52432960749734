import React from "react";
import './Onboarding.css';

const Step1 = () => {
    return (
        <div className="Step Step1">
          <div className="StepTitle">Welcome to <span className="special">Harmony Amor</span></div>
          <div className="StepSubtitle">To find your perfect match let's start by creating your unique profile</div>
        </div>
    )
}

export default Step1;