import React from "react";
import classNames from "classnames";
import './Input.css';

export const Input = ({
  prefix = "",
  postfix = "",
  autoComplete = "on",
  type,
  name = "",
  placeholder = "",
  note = "",
  error = "",
  success = false,
  className = "",
  wrapperClassName = "",
  maxLength,
  onChange = (e) => undefined,
  onBlur = (e) => undefined,
  disabled = false,
  viewOnly = false,
  ...restProps
}) => {
  return (
    <div className={wrapperClassName}>
      <div className="relative">
        <input
          autoComplete={autoComplete}
          type={type}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          maxLength={maxLength}
          className={classNames(
            "input",
            {
              "input--default": !error && !success,
              "border-orange-500": !!error,
              "border-green-500": success,
              "bg-gray-50": disabled,
              "bg-slate-100": viewOnly,
              "input--with-postfix": !!postfix,
              "input--with-pretfix": !!prefix,
            },
            className
          )}
          disabled={disabled || viewOnly}
          {...restProps}
        />
        {
          !!postfix && (
            <span className="input-postfix">{postfix}</span>
          )
        }
        {
          !!prefix && (
            <span className="input-prefix">{prefix}</span>
          )
        }
        {
          error && <div id={`${name}-error`} className="input-error">{error}</div>
        }
        {
          !error && note && <div className="input-note">{note}</div>
        }
      </div>
    </div>
  );
};
