import { useEffect } from 'react';
import useTelegram from './useTelegram';

const useBackButton = (fn) => {
  const { tg } = useTelegram();
  useEffect(() => {
    tg.BackButton.show();
    tg.BackButton.onClick(fn);

    return () => {
      tg.BackButton.offClick(fn);
      tg.BackButton.hide();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useBackButton;