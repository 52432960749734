import React, { createContext, useContext, useReducer, useCallback } from 'react';
import useApi from './api';
import { DataContext } from './DataContext.js';

// Определение контекста
const CardsContext = createContext();

// Actions
const actions = {
  FETCH_START: 'FETCH_START',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAIL: 'FETCH_FAIL',
  SWIPE_CARD: 'SWIPE_CARD'
};

// Reducer для управления состоянием
function cardsReducer(state, action) {
  switch (action.type) {
    case actions.FETCH_START:
      return { ...state, isLoading: true, isOver: false };
    case actions.FETCH_SUCCESS:
      return { ...state, cards: [...action.payload, ...state.cards], isLoading: false, loaded: true, isOver: action.payload.length < 10};
    case actions.FETCH_FAIL:
      return { ...state, isLoading: false, isOver: true };
    case actions.SWIPE_CARD:
      return { ...state, cards: state.cards.filter((_, index) => index !== state.cards.length - 1) };
    default:
      return state;
  }
}

// Провайдер контекста
export const CardsProvider = ({ children }) => {
  const { swipeChangeData } = useContext(DataContext);
  const [state, dispatch] = useReducer(cardsReducer, { cards: [], isLoading: false, isOver: false, loaded: false });
  const { getRandomUsers } = useApi();

  const loadUsers = useCallback(async (user) => {
    dispatch({ type: actions.FETCH_START });
    try {
      const people = await getRandomUsers(user);

      if (!people || people.length === 0) {
        dispatch({ type: actions.FETCH_FAIL });
      } else {
        dispatch({ type: actions.FETCH_SUCCESS, payload: people });
      }
    } catch (error) {
      dispatch({ type: actions.FETCH_FAIL });
    }
  }, [getRandomUsers]);

  const swipeCard = useCallback((direction, card, user, setLike, setDislike, setSuperlike) => {
    let method = null;

    switch (direction) {
      case 'right':
        method = setLike;
        break;
      case 'left':
        method = setDislike;
        break;
      case 'up':
        method = setSuperlike;
        break;
      default:
        return;
    }

    method(user?.telegram_id, card?.telegram_id).then(response => {
      if (response && response.success) {
        dispatch({ type: actions.SWIPE_CARD });
        swipeChangeData(direction); // Reload balance
      }
    });
  }, [swipeChangeData]);

  return (
    <CardsContext.Provider value={{ state, loadUsers, swipeCard }}>
      {children}
    </CardsContext.Provider>
  );
};

// Hook для доступа к контексту
export const useCardsContext = () => useContext(CardsContext);
