import React, { useContext, useEffect, useState } from "react";
import { DataContext } from '../../services/DataContext';
import Navigation from "../../components/Navigation";
import Clicker from "../../components/Clicker";
// import GroupWidget from "../../components/GroupWidget";
import ScoreBar from "../../components/ScoreBar";
import PlaceAndScore from "../../components/PlaceAndScore";
import { Link } from "react-router-dom";

import './BalancePage.css';

const BalancePage = () => {
    const { data: { user }, setData: setUserData } = useContext(DataContext);
    const [data, setData] = useState({
        multitap: user.multiple_clicks,
        balance: user.balance,
        dailyCoins: user.daily_coins,
        miningCoins: 0
    })

    useEffect(() => {
        if (data.miningCoins === 0) {
            setUserData(store => ({
                ...store, user: {
                    ...store.user,
                    balance: data.balance,
                    daily_coins: data.dailyCoins
                }
            }))
        }
    }, [data.miningCoins, data.balance, data.dailyCoins, setUserData])

    return (
        <div className="Page PageBalance">
            {/* <GroupWidget group={user.group} /> */}
            <PlaceAndScore score={data.balance} />
            <Clicker data={data} setData={setData} />
            <ScoreBar limitCoins={user.limit_coins} dailyCoins={data.dailyCoins} />
            <div className="PageBalanceFooter">
                <Link className="PageBalanceFooterLink" to="/buy">🚀<span>Get coins immediately</span>🚀</Link>
            </div>
            <Navigation />
        </div>
    )
}

export default BalancePage;