import React from "react";
import './Onboarding.css';

const Step3 = () => {
    return (
        <div className="Step Step3">
          <div className="StepTitle">Enjoy your dating</div>
          <div className="StepSubtitle">Don't wait any more! <br />Find your soul mate right now!</div>
        </div>
    )
}

export default Step3;