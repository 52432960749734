import { Outlet, Navigate } from "react-router-dom";
import { useContext } from "react";
import { DataContext } from "../../services/DataContext";

const Wrapper = () => {
    const { data } = useContext(DataContext);
    const { user } = data;

    console.log(user);

    if (!user || !user.active) {
        // Navigate to onboarding
        return <Navigate to="/onboarding" replace={true} />
    }

    return (<Outlet />)
};

export default Wrapper;