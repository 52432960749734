import React from "react";
import './RatingList.css';
import { PiHeartStraight } from "react-icons/pi";
import { moneyShorts } from '../../utils/money';

const RatingList = ({ list }) => {
    return (
        <ul className="RL-list">
            {
                list?.map((item, index) => (
                    <li className="RL-item" key={index}>
                        <span className="RL-index">{index + 1}</span>
                        <img src={item.avatar} alt={item.name} className="RL-img" />
                        <span className="RL-itemContent">
                            <span className="RL-name">{item.name}, {item.age}</span>
                            <span className="RL-geo"><span className="RL-coins">{moneyShorts(123500)}</span></span>
                        </span>
                        <button onClick={() => console.log(item.name)} className="RL-direct">
                            <PiHeartStraight className="icon" />
                        </button>
                    </li>
                ))
            }
        </ul>
    )
}

export default RatingList;