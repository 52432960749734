import React from "react";
import './Switcher.css';

const Switcher = ({ activeIndex, tabs, onChange }) => {
    return (
        <div className="switcher">
            {
                tabs.map((tab, index) => (
                    <div className={index === activeIndex ? 'switcherTab switcherTabActive' : 'switcherTab'} key={tab} onClick={() => onChange(index)}>{tab}</div>
                ))
            }
        </div>
    )
}

export default Switcher;