export function checkImageExists(url) {
    return new Promise((resolve, reject) => {
        // Создаем новый объект изображения
        const img = new Image();

        // Обработчик успешной загрузки изображения
        img.onload = () => resolve(true);

        // Обработчик ошибки загрузки изображения
        img.onerror = () => resolve(false);

        // Устанавливаем URL изображения для проверки
        img.src = url;
    });
}