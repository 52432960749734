import React from "react";
import RangeSlider from "../../components/Range";
import { DataContext } from "../../services/DataContext";
import "./ProfileFilters.css";
// import classNames from "classnames";
import Button from "../../components/Button";
import { toast } from 'react-toastify';
import useApi from "../../services/api";


const ProfileFilters = ({ user }) => {
    const { initData } = React.useContext(DataContext);
    const { updateUserData } = useApi();
    const { telegram_id, minAge, maxAge } = user;
    const [age, setAge] = React.useState({ min: minAge, max: maxAge });
    // const [withPhoto, setWithPhoto] =React.useState(withPhotoDefault);

    const submit = React.useCallback(async (e) => {
        e.preventDefault();

        const { success } = await updateUserData(telegram_id, {
            minAge: age.min, 
            maxAge: age.max
        });
        if (success) {
            toast(<div className="toast-msg">Your profile data was updated</div>);
            initData();
        }
    }, [telegram_id, age, updateUserData, initData]);

    return (
        <>
            <div className="FiltersTitle">Select the search settings</div>
            <div className="FiltersBlock FiltersRange">
                <div className="FiltersRange-title">Age range</div>
                <div className="FiltersRange-value"><span>{age.min}</span> - <span>{age.max}</span></div>
                <RangeSlider min={18} max={50} step={1} value={age} onChange={setAge} />
            </div>
            {/* <div className="FiltersBlock FiltersPhoto">
                <div className="FiltersPhoto-title">With photo only</div>
                <div className="FiltersPhoto-checkarea">
                    <div className={classNames("FiltersPhoto-checkareaItem", withPhoto ? "FiltersPhoto-checkareaItem--active" : "")} onClick={() => {setWithPhoto(true)}}>Yes</div>
                    <div className={classNames("FiltersPhoto-checkareaItem", withPhoto ? "" : "FiltersPhoto-checkareaItem--active")} onClick={() => {setWithPhoto(false)}}>No</div>
                </div>
            </div> */}
            <Button className="ButtonAccent form-submit" onClick={submit}>Save</Button>
        </>
    )
}

export default ProfileFilters