import React, { useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion"
import useApi from "../../services/api";
import { useDebounce } from '../../hooks/useDebounce';
import './Clicker.css';
import { PiHeartFill } from "react-icons/pi";

const Clicker = ({ data, setData }) => {
    const { saveClicks } = useApi();
    const clickerContainer = useRef(null);
    const [clicks, setClicks] = useState([])
    const [coinStyle,setCoinStyle] = useState({
        translateZ: 0,
        rotateX: 0,
        rotateY: 0
    });
    

    const updateUserTotalCoins = useDebounce(async() => {
        const { success } = await saveClicks(data.miningCoins);
        if (success){
            setData(data => ({ 
                ...data, 
                miningCoins: 0
            }));
        }
    }, 3000);

    const handleClick = () => {
        const coins = data.miningCoins + data.multitap;
        setData(data => ({ 
            ...data, 
            dailyCoins: data.dailyCoins - data.multitap,
            balance: data.balance + data.multitap,
            miningCoins: data.miningCoins + data.multitap
        }));

        updateUserTotalCoins(coins);
    }

    const handleTouchStart = (event) => {
        handleClick();
        if (clickerContainer.current) {
            const shiftX = Math.floor(Math.random() * 21) - 10;
            const clickPoint = event.touches[0];
            const rect = clickerContainer.current.getBoundingClientRect()
            const skewX = rect.left + rect.width / 3;
            const skewY = rect.top + rect.height / 2;
            const rotateX = (skewY - clickPoint.clientY) / 10;
            const rotateY = (clickPoint.clientX - skewX) / 10;
            setCoinStyle({
                translateZ: -5,
                rotateX: rotateX,
                rotateY: rotateY
            });
            const clickData = {
                id: `${Date.now()}`,
                value: (<PiHeartFill />),
                x: clickPoint.clientX + shiftX,
                y: clickPoint.clientY
            };
            setClicks(clicksArr => [...clicksArr, clickData])
            setTimeout(() => {
                setClicks(clicksArr => clicksArr.filter(item => item.id !== clickData.id))
            }, 1000)
        }
    }

    const handleTouchEnd = () => {
        setCoinStyle({
            translateZ: 0,
            rotateX: 0,
            rotateY: 0
        })
    }

    return (
        <div className="ClickerPanel">
            <div className="Clicker">
                <div 
                    ref={clickerContainer} 
                    className="ClickerCoin" 
                    onTouchStart={(e) => handleTouchStart(e)} 
                    onTouchEnd={(e) => handleTouchEnd(e)}
                    style={{transform: `translateZ(${coinStyle.translateZ}px) rotateX(${coinStyle.rotateX}deg) rotateY(${coinStyle.rotateY}deg)`}}
                >
                    <svg width="300" height="300" viewBox="0 0 77 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M38.455 69.486c73.574-45.738 23.08-90.344 0-59-23.08-31.344-73.574 13.262 0 59Z" fill="#c047bc"/>
                    </svg>
                </div>
                <div className="ClickerCoinAmount">
                    <AnimatePresence>
                        {clicks.map(click => (
                            <motion.div className="ClickerCoinAmountDiv" key={click.id}
                                initial={{
                                    opacity: 1,
                                    y: click.y - 50,
                                    x: click.x
                                }}
                                animate={{
                                    opacity: 0,
                                    y: click.y - 200
                                }}
                                exit={{ opacity: 0 }}
                                transition={{
                                    duration: 1
                                }}
                            >
                                <div className="ClickerCoinAmountDivText">{click.value}</div>
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </div>
                <div className="ClickerNote">Tap on heart to get more coins</div>
            </div>
        </div>
    )
}

export default Clicker;