import { useState, useEffect } from 'react';
import useApi from '../services/api';

export default function useGroupInfo(id) {
    const { fetchGroupData } = useApi();
    const [group, setGroupData ] = useState();

    useEffect(() => {
        const getGroupFromDB = async () => {
            const data = await fetchGroupData(id);
            setGroupData(data);
        }

        if (!group) getGroupFromDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return group;
}