import React, { useContext, useEffect } from "react";
import './MainPage.css';
import Cards from '../../components/Cards';
import Balance from '../../components/Balance';
import GroupWidget from "../../components/GroupWidget";
import { DataContext } from '../../services/DataContext';
import Navigation from "../../components/Navigation/Navigation";
import useTelegram from '../../hooks/useTelegram';
import { toast } from 'react-toastify';
import { PiInfo } from "react-icons/pi";

const MainPage = () => {
    const { data } = useContext(DataContext);
    const { user: { allows_write_to_pm } } = useTelegram();

    useEffect(() => {
        if (!allows_write_to_pm) {
            toast(<div className="toast-msg"><PiInfo className="toast-icon" style={{ fontSize: '48px' }} />To allow other users to write to you, change your telegram settings</div>, { autoClose: false });
        }
    }, [allows_write_to_pm])

    return (
        <div className="Page PageMain">
            <div className="PageMainHeader">
                <GroupWidget group={data.user?.group}/>
                {data.user && data.user?.balance !== undefined ? <Balance amount={data.user?.balance} /> : <></>}
            </div>
            <Cards groupId={data.user?.group?.id} />
            <div className="PageMainFooter">
                <Navigation />
            </div>
        </div>
    )
}

export default MainPage;