import React, { useState, useCallback, useMemo } from "react";
import { PiHeartStraight, PiX, PiTelegramLogo, PiHeartBreak, PiShootingStar} from "react-icons/pi";
import './LikesList.css';
import useTelegram from "../../hooks/useTelegram";
import useApi from "../../services/api";
import { SWIPE_TYPE } from '../../services/constants';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { TbHearts } from "react-icons/tb";

const LikesList = ({ users, type = SWIPE_TYPE.LIKE, updateList }) => {
    const { tg } = useTelegram();
    const { setDismatch, setMatch } = useApi();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState();

    const superlikesUser = useMemo(() => users.filter(user => user.status === SWIPE_TYPE.SUPERLIKE), [users]);

    const openModal = useCallback((user) => {
        setSelectedUser(user);
        setModalIsOpen(true);
    }, [setSelectedUser, setModalIsOpen]);

    const closeModal = useCallback(() => {
        setModalIsOpen(false);
    }, [setModalIsOpen]);

    const acceptLike = useCallback(async (item) => {
        await setMatch(item.like_id);
        await updateList();
        toast(<div className="toast-msg"><TbHearts className="toast-icon" /> Wow it's a match!</div>);
    }, [updateList, setMatch]);

    const declineLike = useCallback(async (item) => {
        await setDismatch(item.like_id);
        await updateList();
        toast(<div className="toast-msg"><PiHeartBreak className="toast-icon" /> Ok. You will find someone better :)</div>);
    }, [updateList, setDismatch]);

    return (
        <div className="LikesList">
            {
                superlikesUser && superlikesUser.length > 0 && (
                    <div className="LikesListSupers">
                        {
                            superlikesUser.map(item => (
                                <div key={item.like_id} className="LikesListItem">
                                    <div className="LikesListItemInner">
                                        <div className="LikesListItemImage">
                                            <img className="LikesListItemAvatar" src={item.avatar} alt={`${item.first_name} ${item.last_name}`} onClick={() => openModal(item)} />
                                        </div>
                                        <div className="LikesListItemContent">
                                            <div className="LikesListItemContentTitle" onClick={() => openModal(item)}>{item.first_name}, {item.age}</div>
                                        </div>
                                        {
                                            type === SWIPE_TYPE.LIKE && (
                                                <div className="LikesListItemActions">
                                                    <button onClick={() => declineLike(item)} className="LikesListBtn LikesListBtnSkip">
                                                        <PiX className="icon" />
                                                    </button>
                                                    <button onClick={() => acceptLike(item)} className="LikesListBtn LikesListBtnMatch"
                                                        ><PiShootingStar className="icon" />
                                                    </button>
                                                </div>
                                            )
                                        }
                                        {
                                            type === SWIPE_TYPE.MATCH && (
                                                <div className="LikesListItemActions">
                                                    <button
                                                        onClick={() => {
                                                            tg.openTelegramLink(`https://t.me/${item.slug}`);
                                                            tg.close();
                                                        }}
                                                        className="LikesListBtn LikesListBtnMatch"
                                                    >
                                                        <PiTelegramLogo className="icon" />
                                                    </button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )
            }
            <div className="LikesListSimple">
                {
                    users.filter(user => user.status !== SWIPE_TYPE.SUPERLIKE).map(item => (
                        <div key={item.like_id} className="LikesListItem">
                            <div className="LikesListItemInner">
                                <div className="LikesListItemImage">
                                    <img className="LikesListItemAvatar" src={item.avatar} alt={`${item.first_name} ${item.last_name}`} onClick={() => openModal(item)} />
                                </div>
                                <div className="LikesListItemContent">
                                    <div className="LikesListItemContentTitle" onClick={() => openModal(item)}>{item.first_name}, {item.age}</div>
                                </div>
                                {
                                    type === SWIPE_TYPE.LIKE && (
                                        <div className="LikesListItemActions">
                                            <button onClick={() => declineLike(item)} className="LikesListBtn LikesListBtnSkip">
                                                <PiX className="icon" />
                                            </button>
                                            <button onClick={() => acceptLike(item)} className="LikesListBtn LikesListBtnMatch">
                                                <PiHeartStraight className="icon" />
                                            </button>
                                        </div>
                                    )
                                }
                                {
                                    type === SWIPE_TYPE.MATCH && (
                                        <div className="LikesListItemActions">
                                            <button
                                                onClick={() => {
                                                    tg.openTelegramLink(`https://t.me/${item.slug}`);
                                                    tg.close();
                                                }}
                                                className="LikesListBtn LikesListBtnMatch"
                                            >
                                                <PiTelegramLogo className="icon" />
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="User Photo"
                    ariaHideApp={false}
                    className="LikesListModal"
                    overlayClassName="LikesListModalOverlay"
                >
                    <button onClick={closeModal} className="LikesListModalClose">
                        <PiX className="icon" />
                    </button>
                    {
                        selectedUser && (
                            <div className="LikesListModalContent">
                                <div className="LikesListModalImage">
                                    <img className="LikesListModalAvatar" src={selectedUser.avatar} alt={`${selectedUser.first_name} ${selectedUser.last_name}`} />
                                </div>
                                <div className="LikesListModalText">
                                    <div className="LikesListItemTitle">{selectedUser.first_name}, {selectedUser.age}</div>
                                    {selectedUser.bio && <div className="LikesListItemBio">{selectedUser.bio}</div>}
                                </div>
                            </div>
                        )
                    }
                </Modal>
        </div>
    )
}

export default LikesList;