import React, { useRef, useEffect } from 'react';
import { useCardsContext } from '../../services/CardsContext'; // Подключение контекста
import { useContext } from 'react';
import { DataContext } from '../../services/DataContext'; // Ваш контекст данных
import useApi from '../../services/api'; // Ваши API хуки
import { PiHeartStraight, PiX, PiSpinnerGap, PiShootingStar } from "react-icons/pi";
import TinderCard from 'react-tinder-card';
import './Cards.css';

const Cards = ({ groupId }) => {
    const { user } = useContext(DataContext).data;
    const { state: { cards, isLoading, isOver, loaded }, loadUsers, swipeCard } = useCardsContext();
    const { setLike, setDislike, setSuperlike } = useApi();
    const cardRefs = useRef([]);

    useEffect(() => {
        if (!isLoading && !isOver && cards.length < 10) {
            loadUsers(user);
        }
    }, [cards.length, isLoading, isOver, loadUsers, user]);

    const onSwipe = (direction, card) => {
        swipeCard(direction, card, user, setLike, setDislike, setSuperlike);
    };

    const swipe = (dir) => {
        const cardRef = cardRefs.current[cards.length - 1];
        if (cardRef) {
            cardRef.swipe(dir);
        }
    };

    return (
        <div className="cardList">
            {
                loaded ? (
                    <div className="cardWrapper">
                        {cards.map((card, index) => (
                            <TinderCard
                                ref={ref => ref && (cardRefs.current[index] = ref)}
                                onSwipe={(dir) => onSwipe(dir, card)}
                                preventSwipe={['down']}
                                className="swipe"
                                key={index}
                                swipeRequirementType="position"
                                swipeThreshold={20}
                            >
                                <div style={{ backgroundImage: `url(${card.avatar})` }} className={`card ${index === cards.length - 1 ? 'cardActive' : ''}`}>
                                    <div className="cardInfo">
                                        <h3 className="cardInfoTitle">{card.first_name}, {card.age}</h3>
                                        {card.bio && <div className="cardInfoBio">{card.bio}</div>}
                                    </div>
                                </div>
                            </TinderCard>
                        ))}
                        {cards.length === 0 && <div className="card cardEmpty">
                                <div className="cardEmptyInfo">
                                    <h3 className="cardEmptyTitle">No more lovers?<br /><br />Try to come back later or search in another group</h3>
                                </div>
                            </div>}
                    </div>
                ) : (
                    <div className="cardWrapper">
                        <div className="card cardLoading">
                            <div className="cardEmptyInfo">
                                <h3 className="cardLoadingIcon">
                                    <PiSpinnerGap size={48} />
                                </h3>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className='buttons'>
                <button onClick={() => swipe('left')} className="btn btnSkip"><PiX className="icon" /></button>
                <button onClick={() => swipe('up')} className="btn btnSuperLike"><PiShootingStar className="icon" /></button>
                <button onClick={() => swipe('right')} className="btn btnMatch"><PiHeartStraight className="icon" /></button>
            </div>
        </div>
    );
};

export default Cards;
