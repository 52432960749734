import React from "react";
import { Textarea, Label, Input, Radio } from '../../components/common';
import './Onboarding.css';

const Step2 = ({ sex, setSex, age, setAge, name, setName, bio, setBio }) => {
    return (
        <div className="Step Step2">
          <div className="StepForm">
            <div className="StepTitle">Tell about yourself</div>
            <form className="form">
              <div className="StepFormGroup">
                <div className="StepFormLabel">First name</div>
                <Input className="StepFormInput" wrapperClassName="StepFormInputWrapper" type="text" value={name} onChange={(e) => setName(e.target.value)} name="first_name"/>
              </div>
              <div className="StepFormGroup">
                <div className="StepFormLabel">Age</div>
                <Input className="StepFormInput" type="number" value={age} onChange={(e) => setAge(e.target.value)} name="age"/>
              </div>
              <div className="StepFormGroup">
                <div className="StepFormLabel">Sex</div>
                <div className="StepFormSexGroup">
                  <Radio
                    name="sex"
                    value={sex === "male"}
                    onChange={() => setSex("male")}
                    checked={sex === "male"}
                    id="male"
                    className="StepFormSex"
                    labelClassName="StepFormSexLabel"
                    inputClassName="StepFormSexInput"
                  >
                    <span className="StepFormSexTitle">Male</span>
                  </Radio>
                  <Radio
                    name="sex"
                    value={sex === "female"}
                    onChange={() => setSex("female")}
                    checked={sex === "female"}
                    id="female"
                    className="StepFormSex"
                    labelClassName="StepFormSexLabel"
                    inputClassName="StepFormSexInput"
                  >
                    <span className="StepFormSexTitle">Female</span>
                  </Radio>
                </div>
              </div>

              <div className="StepFormGroup StepFormGroupTextarea">
                <Label label="About" className="StepFormLabel">
                  <Textarea className="StepFormTextarea" name="bio" value={bio} onChange={(e) => setBio(e.target.value)} placeholder="Tell a few words about yourself" resize={false} maxLength={120} note="Maximum 120 symbols" />
                </Label>
              </div>
            </form>
          </div>
        </div>
    )
}

export default Step2;