import React from "react";
import './Loader.css';

const Loader = () => {
    return (
        <div className="Loader">
          <svg className="LoaderHearts" width="119.813" height="75.5" viewBox="0 0 119.813 75.5" version="1.1">
            <g className="LoaderHeartsGroup">
              <path className="LoaderHeartsLeft" strokeWidth="1" fill="none" d="M37.813,10.500 C18.123,-16.640 -21.710,13.208 14.986,51.395 C16.616,53.091 14.117,62.025 10.813,67.500 C19.189,64.029 21.103,63.223 25.985,61.223 C29.502,63.967 33.434,66.731 37.813,69.500 C111.387,23.762 60.893,-20.844 37.813,10.500"/>
              <path className="LoaderHeartsRight" strokeWidth="1" fill="none" d="M23.724,9.669 C41.800,-15.323 78.368,12.163 44.680,47.328 C43.183,48.890 45.478,57.116 48.511,62.158 C40.821,58.962 39.065,58.220 34.583,56.378 C31.353,58.905 27.744,61.451 23.724,64.000 C13.241,57.463 5.501,50.951 -0.015,44.721 C10.624,34.265 21.140,19.374 17.979,3.867 C20.015,5.341 21.957,7.262 23.724,9.669 Z"/>
            </g>
          </svg>
        </div>
    )
}

export default Loader;