import React, { useState, useEffect, useCallback } from "react";
import './ProfilePage.css';
import { checkImageExists } from '../../utils/images';
import MalePlaceholder from '../../assets/images/avatar/men-placeholder.webp';
import FemalePlaceholder from '../../assets/images/avatar/women-placeholder.webp';
import { PiSpinnerGap } from "react-icons/pi";
import { ProfileForm } from '../../components/Forms/ProfileForm';
import { toast } from 'react-toastify';
import useApi from '../../services/api';

const ProfileUser = ({ user }) => {
    const { updateUserData } = useApi();
    const { telegram_id, first_name, age, sex, bio } = user;
    const [loaded, setLoaded] = useState(false);
    const [userImage, setUserImage] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const imageUrl = `https://vfonzbsnatcrbykoagvk.supabase.co/storage/v1/object/public/images/user/${telegram_id}.jpg`;

            return checkImageExists(imageUrl).then(exists => {
                setUserImage(exists ? imageUrl : sex === 'male' ? MalePlaceholder : FemalePlaceholder);
                setLoaded(true);
            });
        }
        
        fetchData();
    }, [sex, telegram_id]);

    const submit = useCallback(async (e) => {
        e.preventDefault();

        if (e.target.age.value <= 0) {
            toast(<div className="toast-msg toast-msg--error">We don't think it's your real age. Check it again</div>);
            return;
        }

        if (e.target.age.value === `${age}` && e.target.bio.value === (bio || "")) {
            toast(<div className="toast-msg">No changes. That's ok too</div>);
            return;
        }

        const { success } = await updateUserData(telegram_id, {
            age: e.target.age.value, 
            bio: e.target.bio.value
        });
        if (success) toast(<div className="toast-msg">Your profile data was updated</div>);
    }, [telegram_id, age, bio, updateUserData]);

    return (
        <>
            {
                loaded ? <>
                <div className="PageProfileHeader">
                    <div style={{ backgroundImage: `url(${userImage})` }} className="PageProfileImage">
                        <div className="PageProfileInfo">
                            <h3 className="PageProfileName">{first_name}</h3>
                        </div>
                    </div>
                </div>
                <div className="PageProfileForm">
                    <ProfileForm age={age} bio={bio} submit={submit} />
                </div>
            </> : (
                    <div className="cardWrapper">
                        <div className="card cardLoading">
                            <div className="cardEmptyInfo">
                                <h3 className="cardLoadingIcon">
                                    <PiSpinnerGap size={48} />
                                </h3>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default ProfileUser;