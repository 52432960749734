import React from "react";
import classNames from "classnames";
import './Textarea.css';


export const Textarea = ({
  name = "",
  placeholder = "",
  error = "",
  onChange,
  onBlur = () => undefined,
  disabled = false,
  viewOnly = false,
  resize = false,
  className = "",
  wrapperClassName = "",
  rows = 3,
  note = "",
  ...rest
}) => {
  return (
    <div className={wrapperClassName}>
      <textarea
        rows={rows}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        name={name}
        className={classNames(
          "textarea",
          {
            "theme-control-border": !error,
            "theme-border-error": !!error,
            "textarea--resize-none": !resize
          },
          className
        )}
        disabled={disabled || viewOnly}
        {...rest}
      />
      {
        note && <div id={`${name}-note`} className="textarea-note">{note}</div>
      }
      {
        error && <div id={`${name}-error`} className="textarea-error">{error}</div>
      }
    </div>
  );
};
