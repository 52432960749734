import React from "react";
import './Balance.css';
import { Link } from "react-router-dom";
import { PiCoins } from "react-icons/pi";
import { formatterMoney } from '../../utils/money';


const Balance = ({ amount }) => {
    return (
        <div className="balanceWrapper">
            <div className="balance">{formatterMoney.format(amount)}</div>
            <Link className="addBalance" to="balance">
                Earn <PiCoins className="addIcon" />
            </Link>
        </div>
    )
}

export default Balance;