import React from "react";
import './Onboarding.css';
import classNames from "classnames";

const Progress = ({ step }) => {
    return (
        <div className="Progress">
          <span className={classNames("ProgressItem", step === 0 ? "ProgressItemActive" : "")} />
          <span className={classNames("ProgressItem", step === 1 ? "ProgressItemActive" : "")} />
          <span className={classNames("ProgressItem", step === 2 ? "ProgressItemActive" : "")} />
        </div>
    )
}

export default Progress;