import { useState, useEffect } from 'react';
import useApi from '../services/api';
import useTelegram from './useTelegram';

export default function useReferrals(id) {
    const { user } = useTelegram();
    const { fetchReferrals } = useApi();
    const [data, setData ] = useState();

    useEffect(() => {
        const getGroupFromDB = async () => {
            const data = await fetchReferrals(user.id);
            setData(data);
        }

        if (!data) getGroupFromDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return data;
}