import React from "react";
import classNames from "classnames";
import './Radio.css';

export const Radio = ({
    onChange,
    children,
    name,
    id,
    value,
    checked,
    className,
    inputClassName,
    labelClassName
}) => {
    return (
        <div className={classNames("radio", className)} >
            <input
                type="radio"
                id={id}
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                className={classNames(inputClassName)}
            />
            <label
                htmlFor={id}
                className={classNames("radioLabel", labelClassName)}
            >
                {children}
            </label>
        </div>
    );
};
