export const formatterMoney = new Intl.NumberFormat('ru-RU', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});


export const moneyShorts = (number) => {
  // Определяем размерность и соответствующий формат
  if (number < 1000) {
    return number.toString();
  } else if (number < 1000000) {
    return (number / 1000).toFixed(2) + "к";
  } else if (number < 1000000000) {
    return (number / 1000000).toFixed(2) + "М";
  } else if (number < 1000000000000) {
    return (number / 1000000000).toFixed(2) + "B"; // Миллиарды
  } else {
    return (number / 1000000000000).toFixed(2) + "T"; // Триллионы
  }
}
