import React, { useState, useEffect, useContext, useCallback } from "react";
import './Onboarding.css';
import { DataContext } from '../../services/DataContext';
import { useNavigate } from "react-router-dom";
import useApi from "../../services/api";
import Progress from "../../components/Onboarding/Progress.tsx";
import { checkImageExists } from '../../utils/images';
import MalePlaceholder from '../../assets/images/avatar/men-placeholder.webp';
import FemalePlaceholder from '../../assets/images/avatar/women-placeholder.webp';
import WelcomeImg from '../../assets/images/welcome.png';
import GetStartedImg from '../../assets/images/get-started.png';
import Step1 from "../../components/Onboarding/Step1.tsx";
import Step2 from "../../components/Onboarding/Step2.tsx";
import Step3 from "../../components/Onboarding/Step3.tsx";
import { toast } from 'react-toastify';

const Onboarding = () => {
    const { data, initData } = useContext(DataContext);
    const { user } = data;
    const [step, setStep] = useState(0);
    const [userImage, setUserImage] = useState(null);
    const { updateUserData } = useApi();

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState(user.first_name);
    const [age, setAge] = useState(user.age);
    const [sex, setSex] = useState(user.sex || "female");
    const [bio, setBio] = useState(user.bio);

    useEffect(() => {
        const fetchData = async () => {
            const imageUrl = `https://vfonzbsnatcrbykoagvk.supabase.co/storage/v1/object/public/images/user/${user.telegram_id}.jpg`;

            return checkImageExists(imageUrl).then(exists => {
                setUserImage(exists ? imageUrl : user.sex === 'male' ? MalePlaceholder : FemalePlaceholder);
            });
        }
        
        fetchData();
    }, [user.sex, user.telegram_id]);

    if (user && user.active) {
        // Navigate to home page
        navigate("/");
    }

    const submit = useCallback(async () => {
        if (!age || !firstName) {
            toast(<div className="toast-msg toast-msg--error">Fields "Age" and "First name" are required</div>);
            return;
        }

        if (age <= 0) {
            toast(<div className="toast-msg toast-msg--error">We don't think it's your real age. Check it again</div>);
            return;
        }

        const { success } = await updateUserData(user.telegram_id, {
            first_name: firstName,
            bio,
            age,
            sex
        });
        if (success) setStep(step + 1);
    }, [user.telegram_id, updateUserData, step, firstName, bio, age, sex]);

    const activateAccount = useCallback(async () => {
        const { success } = await updateUserData(user.telegram_id, {
            active: true
        });
        if (success) {
            await initData();
            navigate("/");
        }
    }, [initData, user.telegram_id, navigate, updateUserData]);

    const next = () => {
        step === 0 && setStep(step + 1);
        step === 1 && submit();
        step === 2 && activateAccount();
    };

    return (
        <div className="Page PageOnboarding">
            <img
                className="PageOnboardingImg"
                src={
                    (step === 0 && WelcomeImg) ||
                    (step === 1 && userImage) ||
                    GetStartedImg
                }
                alt="Onboarding images"
            />
            <div className="PageOnboardingContent">
                {
                    (step === 0 && <Step1 />) ||
                    (step === 1 && (
                        <Step2
                            name={firstName}
                            setName={setFirstName}
                            age={age}
                            setAge={setAge}
                            bio={bio}
                            setBio={setBio}
                            sex={sex}
                            setSex={setSex}
                        />
                    )) ||
                    <Step3 />
                }
            </div>
            <div className="PageOnboardingFooter">
                <Progress step={step} />

                <button className="PageOnboardingNext" onClick={next} disabled={step === 2 && (!age || !firstName)}>{step === 2 ? "Get started" : "Next"}</button>
            </div>
        </div>
    )
}

export default Onboarding;