import React from "react";
import { useNavigate } from 'react-router-dom';
import useTelegram from "../../hooks/useTelegram";
import useReferrals from "../../hooks/useReferrals";
import useBackButton from "../../hooks/useBackButton";
import { formatterMoney } from '../../utils/money';
import './FriendsPage.css';
import UsersList from "../../components/UsersList";
import Button from "../../components/Button";
import Loader from "../../components/Loader";

import imagePremium from "../../assets/images/premium.png"

const FriendsPage = () => {
    const navigate = useNavigate();
    const { tg, user } = useTelegram();
    const referrals = useReferrals();
    useBackButton(() => navigate(-1));

    if (!referrals) return <Loader />

    const { users, total_count: totalCount, total_premium_count: countOfPremium } = referrals;
    const userReward = countOfPremium * 10000 + (totalCount - countOfPremium) * 2500;

    return (
        <div className="Page PageFriends">
            <div className="PageFriendsInner">
                <div className="PageFriendsImage">
                    <svg fill="#ffffff" width="72" height="72" viewBox="-8 -8 54 54" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid">
                        <path d="M42.001,32.000 L14.010,32.000 C12.908,32.000 12.010,31.104 12.010,30.001 L12.010,28.002 C12.010,27.636 12.211,27.300 12.532,27.124 L22.318,21.787 C19.040,18.242 19.004,13.227 19.004,12.995 L19.010,7.002 C19.010,6.946 19.015,6.891 19.024,6.837 C19.713,2.751 24.224,0.007 28.005,0.007 C28.006,0.007 28.008,0.007 28.009,0.007 C31.788,0.007 36.298,2.749 36.989,6.834 C36.998,6.889 37.003,6.945 37.003,7.000 L37.006,12.994 C37.006,13.225 36.970,18.240 33.693,21.785 L43.479,27.122 C43.800,27.298 44.000,27.634 44.000,28.000 L44.000,30.001 C44.000,31.104 43.103,32.000 42.001,32.000 ZM31.526,22.880 C31.233,22.720 31.039,22.425 31.008,22.093 C30.978,21.761 31.116,21.436 31.374,21.226 C34.971,18.310 35.007,13.048 35.007,12.995 L35.003,7.089 C34.441,4.089 30.883,2.005 28.005,2.005 C25.126,2.006 21.570,4.091 21.010,7.091 L21.004,12.997 C21.004,13.048 21.059,18.327 24.636,21.228 C24.895,21.438 25.033,21.763 25.002,22.095 C24.972,22.427 24.778,22.722 24.485,22.882 L14.010,28.596 L14.010,30.001 L41.999,30.001 L42.000,28.595 L31.526,22.880 ZM18.647,2.520 C17.764,2.177 16.848,1.997 15.995,1.997 C13.116,1.998 9.559,4.083 8.999,7.083 L8.993,12.989 C8.993,13.041 9.047,18.319 12.625,21.220 C12.884,21.430 13.022,21.755 12.992,22.087 C12.961,22.419 12.767,22.714 12.474,22.874 L1.999,28.588 L1.999,29.993 L8.998,29.993 C9.550,29.993 9.997,30.441 9.997,30.993 C9.997,31.545 9.550,31.993 8.998,31.993 L1.999,31.993 C0.897,31.993 -0.000,31.096 -0.000,29.993 L-0.000,27.994 C-0.000,27.629 0.200,27.292 0.521,27.117 L10.307,21.779 C7.030,18.234 6.993,13.219 6.993,12.988 L6.999,6.994 C6.999,6.939 7.004,6.883 7.013,6.829 C7.702,2.744 12.213,-0.000 15.995,-0.000 C15.999,-0.000 16.005,-0.000 16.010,-0.000 C17.101,-0.000 18.262,0.227 19.369,0.656 C19.885,0.856 20.140,1.435 19.941,1.949 C19.740,2.464 19.158,2.720 18.647,2.520 Z"/>
                    </svg>
                </div>
                <div className="PageFriendsCount">{referrals.length} Friends</div>
                <div className="PageFriendsScore">
                    <div className="PageFriendsScoreValue">+{formatterMoney.format(userReward)} Hearts</div>
                    {/* <div className="PageFriendsScoreLeaderboard" onClick={() => navigate("/top100")}>
                        <span>🏆</span>
                        <span>Leaderboard</span>
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L6 6L1 11" stroke="#ffc619" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </div> */}
                </div>

                <div className="PageFriendsInviteInfo">
                    <div className="PageFriendsInviteInfoTitle">Invite friends to get bonuses</div>
                    <div className="PageFriendsInviteInfoContent">
                        <div className="PageFriendsInviteInfoBlock">
                            <div className="PageFriendsInviteInfoBlockImage">
                                <svg width="48" height="48" viewBox="0 0 77 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M38.455 69.486c73.574-45.738 23.08-90.344 0-59-23.08-31.344-73.574 13.262 0 59Z" fill="#c047bc"/>
                                </svg>
                            </div>
                            <div className="PageFriendsInviteInfoBlockText">
                                <div className="PageFriendsInviteInfoBlockTitle">Invite friend</div>
                                <div className="PageFriendsInviteInfoBlockDescription"><span>2,500</span>&nbsp;for you and friend</div>
                            </div>
                        </div>
                        <div className="PageFriendsInviteInfoBlock">
                            <img src={imagePremium} className="PageFriendsInviteInfoBlockImage" alt=""/>
                            <div className="PageFriendsInviteInfoBlockText">
                                <div className="PageFriendsInviteInfoBlockTitle">Friend with&nbsp;<span className="tg">Telegram Premium</span></div>
                                <div className="PageFriendsInviteInfoBlockDescription"><span>10,000</span>&nbsp;for you and friend</div>
                            </div>
                        </div>
                    </div>
                </div>

                <Button className="ButtonAccent PageFriendsInviteButton" onClick={() => {
                    tg.openTelegramLink(`https://t.me/HarmonyAmorBot?start=getInviteLink_${user.id}`);
                    tg.close();
                }}>Invite a friend</Button>

                {users && users.length > 0 && (
                    <div className="PageFriendsUserList">
                        <div className="PageFriendsUserListTitle">Friends List</div>
                        <UsersList users={users} type="referrer"/>
                    </div>
                )}
            </div>
        </div>
    )
}

export default FriendsPage;