import { useCallback } from 'react';
import { checkImageExists } from '../utils/images';
import MalePlaceholder from '../assets/images/avatar/men-placeholder.webp';
import FemalePlaceholder from '../assets/images/avatar/women-placeholder.webp';
import { SWIPE_TYPE } from './constants';
import { toast } from 'react-toastify';

import { useSupabase } from './SupabaseContext';

const useApi = () => {
    const supabase = useSupabase();

    const fetchUserData = useCallback(async (user) => {
        const { id: telegramId } = user;
        const { data, error } = await supabase
            .from('users')
            .select(` 
                *,
                group: groups(
                    *,
                    totalCount: users(count)
                )
            `)
            .eq("telegram_id", telegramId)

        if (error) {
            console.log('Error fetching users', error)
            // toast(<div className="toast-msg toast-msg--error">{error.message}</div>);
        }
        else {
            return data
        }
    }, [supabase])

    const updateUserData = useCallback(async (telegramId, params) => {
        const { error } = await supabase.from('users').update({ ...params }).eq('telegram_id', telegramId);
        
        if (error) {
            console.log(error);
            // toast(<div className="toast-msg toast-msg--error">{error.message}</div>);
            return error
        }

        return { success: true }
    }, [supabase])

    const fetchGroupData = useCallback(async (groupId) => {
        if (!groupId) {
            return null
        }

        const { data, error } = await supabase
            .from('groups')
            .select(` 
                *,
                users(
                    telegram_id, 
                    first_name, 
                    is_premium
                )
            `)
            .eq("id", groupId)
            .limit(8, { referencedTable: 'users'})
            .single()

        if (error) console.log('Error fetching users', error)
        else {
            return data
        }
    }, [supabase])

    const fetchGroups = useCallback(async () => {
        const { data, error } = await supabase
            .from("groups")
            .select(`
                *,
                users: users(count)
            `)
        if (error) console.log('Error fetching groups', error)
        else {
            return data
        }
    }, [supabase])

    const getRandomUsers = useCallback(async (user) => {
        const { data } = await supabase.rpc('get_cards_by_group', {my_sex: user.sex, my_group_id: user.group_id})
            .gte('age', user.minAge)
            .lte('age', user.maxAge);

        const imageCheckedData = data.map(item => {
            const imageUrl = `https://vfonzbsnatcrbykoagvk.supabase.co/storage/v1/object/public/images/user/${item.telegram_id}.jpg`;

            return checkImageExists(imageUrl).then(exists => ({
                ...item,
                avatar: exists ? imageUrl : item.sex === 'male' ? MalePlaceholder : FemalePlaceholder
            }));
        });

        let response = [];

        await Promise.all(imageCheckedData).then(people => {
            response = people;
        });

        return response;
    }, [supabase])


    const fetchLikes = useCallback(async (telegramId) => {
        const { data, error } = await supabase
            .from('swipes')
            .select(`
                id, 
                user_id,
                target_user_id,
                status, 
                user: users ( id, telegram_id, first_name, last_name, age, sex, slug )
            `)
            .match({ target_user_id: telegramId })
            .or(`status.eq.${SWIPE_TYPE.LIKE},status.eq.${SWIPE_TYPE.SUPERLIKE}`)
            .order('status', { ascending: false })


        if (error) {
            // toast.error(error.message);
            return error
        }

        const imageCheckedData = data.map(item => {
            const imageUrl = `https://vfonzbsnatcrbykoagvk.supabase.co/storage/v1/object/public/images/user/${item.user.telegram_id}.jpg`;

            return checkImageExists(imageUrl).then(exists => ({
                ...item.user,
                avatar: exists ? imageUrl : item.user.sex === 'male' ? MalePlaceholder : FemalePlaceholder,
                like_id: item.id,
                status: item.status
            }));
        });

        return imageCheckedData;
    }, [supabase])

    const fetchMatches = useCallback(async () => {
        const { data, error } = await supabase.rpc('get_matches');
        
        if (error) {
            console.log(error);
            // toast(<div className="toast-msg toast-msg--error">{error.message}</div>);
            return error
        }

        const imageCheckedData = data && data.length > 0 ? data.map(item => {
            const imageUrl = `https://vfonzbsnatcrbykoagvk.supabase.co/storage/v1/object/public/images/user/${item.telegram_id}.jpg`;

            return checkImageExists(imageUrl).then(exists => ({
                ...item,
                avatar: exists ? imageUrl : item.sex === 'male' ? MalePlaceholder : FemalePlaceholder,
            }));
        }) : [];

        return imageCheckedData;
    }, [supabase])

    const setDislike = useCallback(async (id, targetId) => {
        const { error } = await supabase
            .from('swipes')
            .insert({ user_id: id, target_user_id: targetId, status: SWIPE_TYPE.DISLIKE })

        if (error) {
            // TODO: toast and stop swipe
            console.log(error);
            // toast(<div className="toast-msg toast-msg--error">{error.message}</div>);
            return { success: false }
        }

        return { success: true }
    }, [supabase])

    const setLike = useCallback(async (id, targetId) => {
        const { error } = await supabase
            .from('swipes')
            .insert({ user_id: id, target_user_id: targetId, status: SWIPE_TYPE.LIKE })

        if (error) {
            // TODO: toast and stop swipe
            console.log(error);
            // toast(<div className="toast-msg toast-msg--error">{error.message}</div>);
            return { success: false }
        }

        return { success: true }
    }, [supabase])

    // COMMENT: it's for v2
    const setSuperlike = useCallback(async (id, targetId) => {
        const { error } = await supabase
            .from('swipes')
            .insert({ user_id: id, target_user_id: targetId, status: SWIPE_TYPE.SUPERLIKE })

        if (error) {
            // TODO: toast and stop swipe
            console.log(error);
            // toast(<div className="toast-msg toast-msg--error">{error.message}</div>);
            return { success: false }
        }

        return { success: true }
    }, [supabase])

    const setMatch = useCallback(async (id) => {
        const { error } = await supabase
            .from('swipes')
            .update({ status: SWIPE_TYPE.MATCH })
            .eq('id', id)

        if (error) {
            console.log(error);
            // toast(<div className="toast-msg toast-msg--error">{error.message}</div>);
            return { success: false }
        }

        return { success: true }
    }, [supabase])

    const setDismatch = useCallback(async (id) => {
        const { error } = await supabase
            .from('swipes')
            .delete()
            .eq('id', id)

        if (error) {
            console.log(error);
            // toast(<div className="toast-msg toast-msg--error">{error.message}</div>);
        }
    }, [supabase])

    const fetchReferrals = useCallback(async (telegramId) => {
        const { data, error } = await supabase.rpc('get_referrals')

        if (error) {
            console.log('Error fetching users', error)
            // toast(<div className="toast-msg toast-msg--error">Error fetching users</div>);
        }
        else {
            return data
        }
    }, [supabase])

    const saveClicks = useCallback(async (count) => {
        const { data, error } = await supabase.rpc('save_clicks', { coins: count })

        if (error) {
            console.log(error);
        } else {
            return data;
        }
    }, [supabase])

    const joinGroup = useCallback(async (telegramId, groupId, title) => {
        const id = toast.loading(groupId ? "Joining...": "Leaving...")
        const { data, error } = await supabase.from('users')
            .update({ group_id: groupId })
            .eq('telegram_id', telegramId)
            .select(` 
                *,
                group: groups(
                    *,
                    totalCount: users(count)
                )
            `).single();

        if (error) {
            console.log(error);
            toast.update(id, {
                render: groupId ? "Joining error. Try again later": "Leaving error. Try again later",
                type: "error",
                autoClose: 2000,
                isLoading: false
            })
        } else {
            toast.update(id, {
                render: groupId ? `You joined ${title} group!` : `You left ${title} group!`,
                type: data.success ? "success" : "info",
                autoClose: 2000,
                isLoading: false
            })
            return data;
        }
    }, [supabase])

    const savePayment = useCallback(async (user_id, wallet, tons, coins, boc) => {
        const { error } = await supabase
            .from('payments')
            .insert({ user_id, wallet, tons, coins, boc })

        if (error) {
            console.log(error);
            return { success: false }
        }

        return { success: true }
    }, [supabase])


    return {
        // Users
        fetchReferrals,
        fetchUserData,
        updateUserData,
        getRandomUsers,
        // Likes
        fetchLikes,
        fetchMatches,
        setDislike,
        setLike,
        setSuperlike,
        setMatch,
        setDismatch,
        // Clicker
        saveClicks,
        // Groups
        fetchGroups,
        fetchGroupData,
        joinGroup,
        // Payments
        savePayment
    }
}

export default useApi;