import React from "react";
import './GroupUsersList.css';

const GroupUsersList = ({ users }) => {
    return (
        <div className="GroupUsersList">
            {users ? users.map(item => (
                <div key={item.telegram_id} className="GroupUsersListItem">
                    <div className="GroupUsersListItemInner">
                        <div className="GroupUsersListItemImage" style={{backgroundImage: `url("https://vfonzbsnatcrbykoagvk.supabase.co/storage/v1/object/public/images/user/${item.telegram_id}.jpg")`}}></div>
                    </div>
                </div>
            )): (
                <>Loader</>
            )}
        </div>
    )
}

export default GroupUsersList;