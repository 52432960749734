import React from "react";
import { Link, useLocation } from "react-router-dom";
import './Navigation.css';

import { PiHouse, PiHeartHalf, PiChatCircleDots, PiGift, PiUser } from "react-icons/pi";

const GridLinks = [
    {
        href: '/',
        icon: <PiHouse className="NavigationButtonIcon" />,
        text: 'Home'
    },
    {
        href: '/receivedlikes',
        icon: <PiHeartHalf className="NavigationButtonIcon" />,
        text: 'Likes'
    },
    {
        href: '/matches',
        icon: <PiChatCircleDots className="NavigationButtonIcon" />,
        text: 'Matches'
    },
    {
        href: '/friends',
        icon: <PiGift className="NavigationButtonIcon" />,
        text: 'Bonuses'
    },
    {
        href: '/profile',
        icon: <PiUser className="NavigationButtonIcon" />,
        text: 'Profile'
    }
]

const GridBtn = ({ href, icon, text, active }) => {
    return (
        <Link className={`NavigationButton ${active ? "NavigationButtonActive" : ""}`} to={href}>
            {icon}
            <span className="NavigationButtonText">{text}</span>
        </Link>
    )
}

const Navigation = () => {
    const { pathname } = useLocation();

    return (
        <div className="Navigation">
            <div className="NavigationButtons">
                {
                    GridLinks.map(link => <GridBtn href={link.href} icon={link.icon} text={link.text} key={link.href} active={pathname === link.href} />)
                }
            </div>
        </div>
    )
}

export default Navigation;