import React, { useContext, useState } from "react";
import './ProfilePage.css';
import { useNavigate } from "react-router-dom";
import { DataContext } from '../../services/DataContext';
import useBackButton from "../../hooks/useBackButton";
import classNames from "classnames";

import ProfileUser from "./ProfileUser";
import ProfileFilters from "./ProfileFilters";

const ProfilePage = () => {
    const navigate = useNavigate();
    const { data } = useContext(DataContext);
    const { user } = data;
    useBackButton(() => navigate(-1));

    const [about, setAbout] = useState(true);

    return (
        <div className="Page PageProfile">
            <div className="PageProfileTabs">
                <div className={classNames("PageProfileTabsItem", about ? "PageProfileTabsItemActive" : "")} onClick={() => {setAbout(true)}}>About you</div>
                <div className={classNames("PageProfileTabsItem", about ? "" : "PageProfileTabsItemActive")} onClick={() => {setAbout(false)}}>Search</div>
            </div>
            <main className="PageProfileMain">
                {
                    about ? <ProfileUser user={user} /> : <ProfileFilters user={user} rangeDefault={{ min: user.minAge, max: user.maxAge }} withPhotoDefault={true} />
                }
            </main>
        </div>
    )
}

export default ProfilePage;