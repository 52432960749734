import { createClient } from '@supabase/supabase-js'
import Cookies from 'js-cookie'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

// Функция для инициализации клиента Supabase
export async function initializeSupabase() {
  // Попытка получить токен из куки
  let accessToken = Cookies.get('accessToken');

  // Если токена нет в куки, делаем запрос для его получения
  if (!accessToken) {
    const response = await fetch(`${supabaseUrl}/functions/v1/getToken`, {
      method: "POST",
      body: JSON.stringify({ initData: window.Telegram.WebApp.initData }),
      headers: {
        "Authorization": `Bearer ${supabaseAnonKey}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error('Проблема при получении токена авторизации');
    }

    const data = await response.json();
    accessToken = data.token; // Предполагается, что токен находится в data.token

    // Сохраняем токен в куки
    Cookies.set('accessToken', accessToken, { expires: 7 }); // Токен сохраняется на 7 дней
  }

  // Возвращаем инициализированный клиент Supabase с токеном
  return createClient(supabaseUrl, supabaseAnonKey, {
    global: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  });
}
