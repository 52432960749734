const DESTINATION_ADDRESS = "0:54ee9ab67465706e4e06780c6c5b7f3425c03e67b720b963667cd7b754c9d10c";

function convertToncoinToNanotons(toncoin) {
    const nanotonsPerToncoin = 1000000000; // 1 Toncoin равен 1,000,000,000 nanotons
    return toncoin * nanotonsPerToncoin;
}

const transactionFor10k = {
    validUntil: Math.floor(Date.now() / 1000) + 360,
    messages: [
        {
            address: DESTINATION_ADDRESS,
            amount: convertToncoinToNanotons(1) //Toncoin in nanotons
        }
    ]
}

const transactionFor20k = {
    validUntil: Math.floor(Date.now() / 1000) + 180,
    messages: [
        {
            address: DESTINATION_ADDRESS,
            amount: convertToncoinToNanotons(2) //Toncoin in nanotons
        }
    ]
}

const transactionFor50k = {
    validUntil: Math.floor(Date.now() / 1000) + 360,
    messages: [
        {
            address: DESTINATION_ADDRESS,
            amount: convertToncoinToNanotons(4) //Toncoin in nanotons
        }
    ]
}

export const plans = {
    10000: {
        tons: 1,
        coins: 10000,
        transaction: transactionFor10k
    },
    25000: {
        tons: 2,
        coins: 25000,
        transaction: transactionFor20k
    },
    50000: {
        tons: 4,
        coins: 50000,
        transaction: transactionFor50k
    }
}