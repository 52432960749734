import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { TonConnectUIProvider } from '@tonconnect/ui-react';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <TonConnectUIProvider manifestUrl="https://harmony-amor.pages.dev/tonconnect-manifest.json">
      <App />
    </TonConnectUIProvider>
  </BrowserRouter>
);

reportWebVitals();
