import React, { useState, useContext } from "react";
import { TonConnectButton, useTonConnectUI, useTonAddress } from '@tonconnect/ui-react';
import './BuyCoinsPage.css';
import { THEME } from '@tonconnect/ui';
import { toast } from 'react-toastify';
import { DataContext } from '../../services/DataContext';
import { plans } from "./coinPlans";
import useApi from "../../services/api";

const BuyCoinsPage = () => {
    const { data: { user } } = useContext(DataContext);
    const [tonConnectUI] = useTonConnectUI();
    const [transactionStatus, setTransactionStatus] = useState('idle');
    const userFriendlyAddress = useTonAddress();
    const { savePayment } = useApi();

    const [buyCoins, setBuyCoins] = useState();

    tonConnectUI.uiOptions = {
        uiPreferences: {
            theme: THEME.DARK,
            borderRadius: 'm',
            colorsSet: {
                [THEME.DARK]: {
                    connectButton: {
                        background: '#C047BC',
                    }
                }
            }
        }
    };

    const handleTransaction = async () => {
        try {
            if (!buyCoins) {
                toast(<div className="toast-msg">Choose coins amount you need</div>);
                return;
            }

            // Устанавливаем статус транзакции в 'pending'
            setTransactionStatus('pending');

            // Предполагается, что tonConnectUI.sendTransaction возвращает Promise
            const { transaction } = plans[buyCoins];
            const { boc } = await tonConnectUI.sendTransaction(transaction);

            if (boc) {
                const response = await savePayment(user.telegram_id, userFriendlyAddress, plans[buyCoins].tons, buyCoins, boc);

                if (response.success) {
                    // Если транзакция успешно завершена, устанавливаем статус в 'success'
                    setTransactionStatus('success');
                    toast(<div className="toast-msg">Your transaction is succesfull</div>);    
                } else {
                    setTransactionStatus('error');
                    toast(<div className="toast-msg">Transaction saving error</div>);  
                }
            } else {
                setTransactionStatus('error');
                toast(<div className="toast-msg">Incorrect transaction</div>);
            }
        } catch (error) {
            // В случае ошибки устанавливаем статус в 'error'
            setTransactionStatus('error');
            console.error('Ошибка при выполнении транзакции: ', error);
            toast(<div className="toast-msg toast-msg--error">Error: transaction failed</div>);
        }
    };

    return (
        <div className="Page PageBuyCoins">
            <div className="PageBuyCoins-header">
                <h1 className="PageBuyCoinsTitleWrapper">
                    <span className="PageBuyCoinsTitle">Allow yourself more</span>
                </h1>
            </div>

            <ul className="PageBuyCoins-list">
                <li className="PageBuyCoins-listItem">
                    <span className="PageBuyCoins-listItemIcon">🚀</span>
                    Save your time to get more coins</li>
                <li className="PageBuyCoins-listItem">
                    <span className="PageBuyCoins-listItemIcon">💌</span>
                    More coins - more swipes and matches</li>
                <li className="PageBuyCoins-listItem">
                    <span className="PageBuyCoins-listItemIcon">💞</span>
                    Get more chances of a dream date</li>
            </ul>
            <div className="PageBuyCoins-wallets">
                <div className="">Your wallet</div>
                <TonConnectButton className="wallet-btn" />
            </div>
            <div className="PageBuyCoins-purchases">
                {
                    Object.values(plans).map(plan => <label key={plan.coins} className={`PageBuyCoins-plan ${buyCoins === plan.coins ? 'PageBuyCoins-plan--active' : ''}`}>
                        <input
                            type="radio"
                            name="amount"
                            value={plan.coins}
                            className="sr-only"
                            onChange={() => setBuyCoins(plan.coins)}
                        />
                        <span className="PageBuyCoins-plan-coins">
                            {plan.coins}
                        </span>
                        <span className="PageBuyCoins-plan-tons">
                            for {plan.tons} TON
                        </span>
                    </label>)
                }
            </div>
            {userFriendlyAddress && transactionStatus !== 'pending' && <button onClick={handleTransaction} className="PageBuyCoins-submit" disabled={transactionStatus === 'pending' || !buyCoins}>
                Buy
            </button>}
        </div>
    )
}

export default BuyCoinsPage;