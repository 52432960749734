import React from "react";
import { useNavigate } from 'react-router-dom';
// import { PiGenderFemale, PiGenderMale} from "react-icons/pi";
import Loader from "../../components/Loader";
import './GroupsList.css';

const GroupsList = ({ groups }) => {
    const navigate = useNavigate();
    return (
        <div className="GroupsList">
            {groups ? groups.map(item => (
                <div key={item.id} className="GroupsListItem" onClick={() => navigate(`/groups/${Math.abs(item.id)}`)}>
                    <div className="GroupsListItemInner">
                        <div className="GroupsListItemImage">
                            <img className="GroupsListItemAvatar" src={`https://vfonzbsnatcrbykoagvk.supabase.co/storage/v1/object/public/images/group/${item.id}.jpg`} alt={`${item.slug}`} />
                        </div>
                        <div className="GroupsListItemContent">
                            <div className="GroupsListItemContentTitle">{item.title}</div>
                            {/* <div className="GroupsListItemMetrics">
                                <div><PiGenderMale/> 125</div>
                                <div><PiGenderFemale/> 300</div>
                            </div> */}
                        </div>
                        <div className="GroupsListItemInvite">
                            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L6 6L1 11" stroke="#C8C7CB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            )): (
                <Loader />
            )}
        </div>
    )
}

export default GroupsList;